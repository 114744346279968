import React from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import styled, { css, withTheme } from 'styled-components';
import { MenuItem } from 'rambler-ui/Menu';
import RamblerSelect from 'rambler-ui/Select';
import Loader from 'rambler-ui/Loader';

import { ruiClass } from 'theme/mixins';
import colors from 'theme/colors';

export const variations = {
  transparent: {
    default: {
      color: colors.text.primary,
      background: 'transparent',
      borderColor: 'transparent',
      placeholderColor: colors.text.disabled
    },
    focused: {
      color: colors.text.primary,
      borderColor: 'transparent',
      placeholderColor: colors.text.disabled
    },
    disabled: {
      color: colors.text.primary,
      background: 'transparent',
      borderColor: 'transparent',
      placeholderColor: colors.text.disabled
    },
    success: {
      color: colors.text.primary,
      background: 'transparent',
      borderColor: 'transparent',
      placeholderColor: colors.text.disabled
    },
    warning: {
      color: colors.error.secondary,
      background: 'transparent',
      borderColor: 'transparent',
      placeholderColor: colors.text.disabled
    },
    error: {
      color: colors.error.primary,
      background: 'transparent',
      borderColor: 'transparent',
      placeholderColor: colors.text.disabled
    }
  }
};

const variation = state => ({ variation }) => {
  const values = variations[variation] && variations[variation][state]
    ? variations[variation][state]
    : null;

  return values && css`
    color: ${values.color};
    background: ${values.background};
    border: 1px solid ${values.borderColor};

    /* Paddings compensation for 'custom' variation */
    ${variation === 'transparent' && css`
      padding-left: 14px;
      padding-right: 14px;
    `}

    ::placeholder {
      color: ${values.placeholderColor};
    }
  `;
};

const MenuItemText = styled.div`
  white-space: nowrap;
  width: 100%;
`;

const Wrapper = styled.div`
  ${({ disabled, hasError, status, theme }) => css`
    &&& {
      ${ruiClass('rui-Input-icon')} {
        width: 20px;
        height: 20px;
      }

      ${ruiClass('rui-Input-iconRight')} {
        right: 5px;
      }

      ${ruiClass('rui-Input-input')} {
        height: 36px;
        border-radius: 4px;

        ${(!disabled && status) && variation(status)}
        ${disabled && variation('disabled')}
        ${(!disabled && !status) && variation('default')}

        ${hasError && css`
          border-color: ${theme.colors.error.primary};
        `}
      }

      input + div {
        border-color: transparent;
      }

      div[role="button"] {
        color: ${theme.colors.button.primaryHover};

        svg,
        path {
          fill: ${theme.colors.text.primary};
          transition-duration: 0.2s;
        }
      }

      ${ruiClass('rui-Select-isOpened')} {
        div[role="button"] svg {
          transform: rotate(180deg);
        }
      }

      ${ruiClass('rui-MenuItem')} {
        :hover {
          color: ${theme.colors.button.primaryHover};
        }
      }
    }
  `}
`;

const AddressItem = styled.div`
  color: #979BA0
`;

const dropDownStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
};

@withTheme
@observer
class Select extends React.Component {
  static defaultProps = {
    labelKey: 'label',
    valueKey: 'value',
    items: [],
    isLoading: false
  };

  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    field: PropTypes.object.isRequired,
    items: PropTypes.array,
    style: PropTypes.object,
    dropdownClassName: PropTypes.string,
    dropdownStyle: PropTypes.string,
    menuClassName: PropTypes.string,
    menuStyle: PropTypes.object,
    multiple: PropTypes.bool,
    multipleType: PropTypes.oneOf(['regular', 'background']),
    clearIcon: PropTypes.bool,
    valuesEquality: PropTypes.func,
    iconElementRenderer: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    icon: PropTypes.node,
    arrowIcon: PropTypes.node,
    size: PropTypes.oneOf(['small', 'medium']),
    variation: PropTypes.oneOf(['regular', 'awesome', 'promo', 'transparent']),
    status: PropTypes.oneOf(['error', 'warning', 'success', 'filled', null]),
    appendToBody: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    customElementRenderer: PropTypes.func,
    rootClassName: PropTypes.string,
    rootStyle: PropTypes.object,
    containerClassName: PropTypes.string,
    containerStyle: PropTypes.object,
    inputMode: PropTypes.bool,
    native: PropTypes.bool,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
    isLoading: PropTypes.bool
  };

  onChange = (value) => {
    const { field, onChange } = this.props;

    if (onChange) {
      return onChange(value);
    }

    value ? field.set(value) : field.clear();
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  @computed get hasError() {
    const { field, valueKey } = this.props;
    return field.has(valueKey) && !!field.$(valueKey).error;
  }

  inputValueRenderer = (value) => {
    const { labelKey } = this.props;
    return value && value[labelKey];
  };

  render() {
    const {
      disabled,
      items,
      field,
      labelKey,
      valueKey,
      icon,
      isLoading,
      status,
      variation,
      customElementRenderer,
      ...rest
    } = this.props;

    const renderer = customElementRenderer || ((item) => item[labelKey]);

    const menuOptions = items
      .map((item) => (
        <MenuItem
          key={item[valueKey]}
          value={item}
        >
          <MenuItemText>
            {renderer(item)}
            {item?.address?.location && <AddressItem>{item.address.location}</AddressItem>}
          </MenuItemText>
        </MenuItem>
      ));

    return (
      <Wrapper
        disabled={disabled}
        hasError={this.hasError}
        status={status}
        variation={variation}
        onKeyDown={this.handleKeyDown}
      >
        <Loader loading={isLoading}>
          <RamblerSelect
            {...rest}
            disabled={disabled}
            dropdownStyle={dropDownStyle}
            status={status}
            icon={icon}
            variation={variation}
            inputValueRenderer={this.inputValueRenderer}
            {...field.bind({ onChange: this.onChange })}
          >
            {menuOptions}
          </RamblerSelect>
        </Loader>
      </Wrapper>
    );
  }
}

export default styled(Select)``;
