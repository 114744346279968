import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { Chip } from 'components/atoms';
import { DoubleTitle } from 'components/molecules';
import { Message } from 'components/ui';

const Row = styled.div`
  ${Chip} + ${Chip} {
    margin-left: 12px;
  }

  ${DoubleTitle} + ${Message} {
    margin-top: 16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;

  ${({ error }) =>
    error &&
    css`
      margin-left: -20px;
      margin-right: -20px;
      padding: 10px 20px 20px 20px;
      background-color: #fdf1f1;
    `}
`;

@withTranslation()
@withRouter
@inject('bookingForm')
@observer
class BedSelector extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    bedsCount: PropTypes.number,
    occupation: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.number.isRequired,
        rates: PropTypes.array.isRequired
      })
    ),
    displayCount: PropTypes.number,
    isExtra: PropTypes.bool,
    selected: PropTypes.number,
    bookingForm: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onSelect: PropTypes.func
  };

  static defaultProps = {
    title: '',
    subTitle: '',
    className: '',
    onSelect: () => null
  };

  handleOnAddTraveller = (e) => {
    e.preventDefault();

    const {
      history,
      location: { pathname },
      bookingForm: { order }
    } = this.props;
    history.push(`/orders/${order.id}/add_travellers`, {
      return_url: pathname
    });
  };

  render() {
    const {
      t,
      title,
      subTitle,
      onSelect,
      bedsCount,
      selected,
      occupation,
      isExtra,
      displayCount,
      ...rest
    } = this.props;
    const isAvailableRates = occupation?.filter(
      ({ rates }) => rates.length > 0
    )?.length;

    return (
      <Wrapper {...rest}>
        <Row>
          <DoubleTitle {...{ title, subTitle }}>
            <Chip
              displayCount={displayCount}
              isExtra={isExtra}
              selected={selected}
              bedsCount={bedsCount}
              onSelect={onSelect}
              isAvailableRates={isAvailableRates}
            />
          </DoubleTitle>
        </Row>
      </Wrapper>
    );
  }
}

export default styled(BedSelector)``;
