import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { pluralize } from 'utils/pluralize';

import BedSelector from '../BedSelector';
import Slot from '../Slot';

const Wrapper = styled.div`
  ${BedSelector} {
    margin-bottom: 33px;
  }
`;

const SlotWrapper = styled.div`
  margin-bottom: 33px;
`;

@inject('bookingForm', 'bookingState')
@observer
class MainSeats extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingForm: PropTypes.object.isRequired,
    bookingState: PropTypes.object.isRequired
  }

  static defaultProps = {
    className: ''
  }

  render() {
    const { bookingForm, bookingState, ...rest } = this.props;

    const { bedsCount, mainOccupation } = bookingState;

    const { mainSlots, selectedMainCount } = bookingForm;

    let subTitle = pluralize(bedsCount, 'место', 'места', 'мест');
    subTitle = [bedsCount, subTitle, 'максимум'].join(' ');

    const bedSelector = {
      title: 'Основных мест',
      subTitle: subTitle,
      onSelect: bookingForm.setSlots,
      bedsCount: bedsCount,
      selected: selectedMainCount,
      occupation: mainOccupation,
      displayCount: selectedMainCount,
      isExtra: false
    };

    const listItems = mainSlots
      .map(o => <Slot key={o.field.id} {...o} />);

    return (
      <Wrapper {...rest}>
        <BedSelector {...bedSelector} />

        <SlotWrapper>
          {listItems}
        </SlotWrapper>
      </Wrapper>
    );
  }
}

MainSeats.propTypes = {
  className: PropTypes.string
};

MainSeats.defaultProps = {
  className: ''
};

export default styled(MainSeats)``;
