import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { pluralize } from 'utils/pluralize';

import BedSelector from '../BedSelector';
import Slot from '../Slot';

const Wrapper = styled.div`
  ${BedSelector} {
    margin-bottom: 33px;
  }
`;

const SlotWrapper = styled.div`
  margin-bottom: 33px;
`;

@inject('bookingForm', 'bookingState')
@observer
class ExtraSeats extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingForm: PropTypes.object.isRequired,
    bookingState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { bookingForm, bookingState, ...rest } = this.props;

    const { extraBedsCount, extraOccupation } = bookingState;

    const { selectedCount, extraSlots, selectedExtraCount } = bookingForm;

    let subTitle = pluralize(extraBedsCount, 'место', 'места', 'мест');
    subTitle = [extraBedsCount, subTitle, 'максимум'].join(' ');

    const bedSelector = {
      title: 'Дополнительных мест',
      subTitle: subTitle,
      onSelect: bookingForm.setSlots,
      bedsCount: extraBedsCount,
      selected: selectedCount,
      occupation: extraOccupation,
      isExtra: true,
      displayCount: selectedExtraCount
    };

    const listItems = extraSlots
      .map(o => <Slot key={o.field.id} {...o} />);

    return (
      <Wrapper {...rest}>
        <BedSelector {...bedSelector} />

        <SlotWrapper>
          {listItems}
        </SlotWrapper>
      </Wrapper>
    );
  }
}

export default styled(ExtraSeats)``;
