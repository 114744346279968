import BaseState from 'forms/BaseState';
import { action, observable, computed } from 'mobx';
import moment from 'utils/moment';
import { t } from 'utils/localization';

import { Field } from 'mobx-react-form';
import { TravellerField, SlotField } from 'forms/Fields';

import _pick from 'lodash/pick';
import _cloneDeep from 'lodash/cloneDeep';
import _flatMap from 'lodash/flatMap';
import _uniqBy from 'lodash/uniqBy';
import _groupBy from 'lodash/groupBy';

import coerceDatesByMinDays from './utils/coerceDatesByMinDays';
import coerceSlotsRates from './utils/coerceSlotsRates';
import coerceSlotsNumber from './utils/coerceSlotsNumber';
import assignTravellers from './utils/assignTravellers';
import assignSlotsPrices from './utils/assignSlotsPrices';
import assignSlotsContract from './utils/assignSlotsContract';
import pricesAlertMessage from './utils/pricesAlertMessage';
import permissionAlertMessage from './utils/permissionAlertMessage';
import stopSalesAlertMessae from './utils/stopSalesAlertMessae';
import parseTariffRestriction from './utils/parseTariffsRestriction';
import getPayment from './utils/getPayment';
import calcRoomsCount from './utils/calcRoomsCount';
import coerceDailyPrices from './utils/coerceDailyPrices';

import exportToQueryParams from './utils/exportToQueryParams';
import filterOccupation from './utils/filterOccupation';

import fields from './fields';

const GENDERS = [
  { value: 'male', label: t('Form.Gender.Male') },
  { value: 'female', label: t('Form.Gender.Female') }
];

class BookingForm extends BaseState {
  setup() {
    return fields;
  }

  makeField(props) {
    // Travellers Field
    const reT = /^slots.\d+.traveller$/;

    if (reT.exec(props.path)) {
      return new TravellerField(props);
    }

    // Slots Field
    const reS = /^slots.\d+$/;

    if (reS.exec(props.path)) {
      return new SlotField(props);
    }

    return new Field(props);
  }

  //
  @action
  dispose() {
    //
  }

  @action
  clear() {
    this.unsetSlots();
    this.setSlots({ count: 1 });
  }

  // Errors
  @observable.ref alert = undefined;

  @computed get hasAlert() {
    return !!this.alert;
  }

  @action
  setAlert(error) {
    this.alert = error;
  }

  @action
  unsetAlert() {
    this.alert = undefined;
  }

  @action
  setAlerMessages({ tariff, prices, currentUser }) {
    let message;

    message = permissionAlertMessage({ tariff, currentUser: this.currentUser });
    if (message) {
      this.setAlert(message);
      return;
    }

    message = pricesAlertMessage({ tariff, prices });
    if (message) {
      this.setAlert(message);
      return;
    }

    message = stopSalesAlertMessae({ tariff, prices });
    if (message) {
      this.setAlert(message);
      return;
    }

    this.unsetAlert();
  }

  // Hints
  @observable.ref hint = undefined;

  @computed
  get hasHint() {
    return !!this.hint;
  }

  @action
  setHint(value) {
    this.hint = value;
  }

  @action
  unsetHint() {
    this.hint = undefined;
  }

  // Disabled
  @computed get isDisabled() {
    return false;
  }

  // Editable mode
  @computed get isEditableMode() {
    return !!this.$('id').value;
  }

  // Current User
  @observable.ref currentUser = undefined;

  @action
  setCurrentUser(user) {
    this.currentUser = user;
  }

  @action
  unsetCurrentUser() {
    this.currentUser = undefined;
  }

  // Order
  @observable.ref order = undefined;

  @action setOrder(order) {
    this.order = order;
  }

  @action unsetOrder() {
    this.order = undefined;
  }

  // Hotel
  @observable.ref hotel = undefined;

  @action setHotel(hotel) {
    this.hotel = hotel;

    const attrs = _pick(hotel, ['id', 'name', 'address']);
    this.set({ hotel: attrs });
  }

  @action unsetHotel() {
    this.hotel = undefined;
    this.update({ hotel: undefined });
  }

  // Contract
  @observable.ref contract = undefined;

  @action
  setContract(contract) {
    this.contract = contract;
    this.set({ contract: contract });
  }

  @action
  unsetContract(contract) {
    this.contract = undefined;
    this.update({ contract: undefined });
  }

  @action
  updateContract(contract) {
    const currentId = this.contract?.id;
    this.setContract(contract);

    Array.from(this.$('slots').fields.values())
      .filter(slot => !slot.$('contract.id').value || slot.$('contract.id').value === currentId)
      .map(slot => slot.update({ contract }));
  }

  // RoomType
  @observable.ref room_type = undefined;

  @action setRoomType(room_type) {
    this.room_type = room_type;

    const attrs = _pick(room_type, ['id', 'name']);
    this.set({ room_type: attrs });
  }

  @action unsetRoomType() {
    this.room_type = undefined;
    this.update({ room_type: undefined });
  }

  // Tariff
  @observable.ref tariff = undefined;

  @action setTariff(tariff) {
    this.tariff = tariff;

    const attrs = _pick(tariff, ['id', 'name']);
    this.set({ tariff: attrs });

    this.onSetTariff(tariff);
  }

  @action unsetTariff() {
    this.tariff = undefined;
    this.update({ tariff: undefined });
  }

  @action
  onSetTariff(tariff) {
    const hints = parseTariffRestriction(tariff);
    this.setHint(hints[0]);
  }

  // Daily Prices
  @observable.ref dailyPrices = [];

  @action setDailyPrices(prices) {
    const { billing_hour } = this.tariff;

    const data = coerceDailyPrices({ billing_hour, prices });
    this.dailyPrices = data;

    this.onSetDailyPrices(data);
  }

  @action unsetDailyPrices(prices) {
    this.dailyPrices = [];
  }

  @action updateDailyPrices(prices) {
    this.setDailyPrices(prices);
    this.coerceSlots();
  }

  onSetDailyPrices(prices) {
    const { tariff, currentUser } = this;
    this.setAlerMessages({ tariff, prices, currentUser });
  }

  // Occupation
  @observable.ref occupation = {};

  @computed get selectedMainCount() {
    return Math.min(this.bedsCount, this.selectedCount);
  }

  @computed get selectedExtraCount() {
    return this.selectedCount - this.selectedMainCount;
  }

  @computed get mainSlots() {
    let rateOptions = this.occupation[this.selectedCount];

    rateOptions = _flatMap(rateOptions);
    rateOptions = _uniqBy(rateOptions, r => r.type);

    // Filter by occupation
    rateOptions = rateOptions
      .filter(rate => rate.occupation === 'main');

    return this.slots
      .filter(field => field.$('rate.occupation').value === 'main')
      .map((field, i) => ({ number: i + 1, rateOptions, field }));
  }

  @computed get extraSlots() {
    let rateOptions = this.occupation[this.selectedCount];

    rateOptions = _flatMap(rateOptions);
    rateOptions = _uniqBy(rateOptions, r => r.type);

    // Filter by occupation
    rateOptions = rateOptions
      .filter(rate => rate.occupation === 'extra');

    return this.slots
      .filter(field => field.$('rate.occupation').value === 'extra')
      .map((field, i) => ({ number: i + this.bedsCount + 1, field, rateOptions }));
  }

  @computed get rateOptions() {
    let data = this.occupation[this.selectedCount];

    data = _flatMap(data);
    data = _uniqBy(data, r => r.type);

    return data;
  }

  @action setOccupation(occupation) {
    this.occupation = occupation;
  }

  @action unsetOccupation() {
    this.occupation = {};
  }

  // Slots
  @observable selectedCount = 1;

  @action coerceSlotsCount() {
    const currentCount = this.selectedCount;
    const occupation = filterOccupation(this.occupation);

    const data = occupation[currentCount] || [];
    if (data.length > 0) return;

    let count = Object.keys(occupation)[0];
    count = Number(count);

    this.selectedCount = count;
  }

  @action
  coerceSlots = () => {
    let slots = this.$('slots').value;
    let deleted;

    // Clear current slots
    this.update({ slots: [] });

    // Coerce slots number
    slots = this.coerceSlotsNumber({ slots });

    // Parse deleted slots
    [slots, deleted] = this.parseSlotsDeleted({ slots });

    // Coerce travellers
    slots = this.coerceSlotsTravellers({ slots });

    // Coerce slots
    slots = this.coerceSlotsRates({ slots });

    // Assign prices
    slots = this.assignSlotsPrices({ slots });

    // Assign contract
    slots = this.assignSlotsContract({ slots });

    // Calc reservations price
    const price = this.calcReservationsPrice({ slots });

    // Calc duration
    const duration = this.calcDuration();

    // Calc rooms buondary
    const count = this.calcRoomsCount();
    this.setRoomsBoundary({ min: 1, max: count });

    const rooms_count = this.coerceRoomsCount({ count });

    // Append deleted
    slots = [...slots, ...deleted];

    this.update({ price, rooms_count, duration, slots });
  }

  @action coerceSlotsNumber({ slots }) {
    const count = this.selectedCount;
    slots = coerceSlotsNumber({ slots, count });

    return slots;
  }

  @action
  parseSlotsDeleted({ slots }) {
    const data = _groupBy(slots, s => (
      s._destroy ? 'deleted' : 'persisted'
    ));

    const persisted = data.persisted || [];
    const deleted = data.deleted || [];

    return [persisted, deleted];
  }

  @action coerceSlotsRates({ slots }) {
    const count = this.selectedCount;

    let occupation = this.occupation[count];
    occupation = _cloneDeep(occupation);

    slots = coerceSlotsRates({ slots, occupation });
    return slots;
  }

  @action coerceSlotsTravellers({ slots }) {
    // Assign travellers
    slots = assignTravellers({ slots });

    return slots;
  }

  @action assignSlotsPrices({ slots }) {
    const { type, billing_hour } = this.tariff;

    const prices = _cloneDeep(this.dailyPrices);
    slots = assignSlotsPrices({ slots, billing_hour, type, prices });

    return slots;
  }

  @action
  assignSlotsContract({ slots }) {
    const {
      order: { contract: order_contract },
      contract: reservation_contract
    } = this;

    slots = assignSlotsContract({
      slots, reservation_contract, order_contract
    });

    return slots;
  }

  @action calcReservationsPrice({ slots }) {
    return slots.reduce((sum, slot) => sum + slot.price, 0);
  }

  @action calcDuration() {
    const { billing_hour } = this.tariff;

    const check_in = this.$('check_in').value;
    const check_out = this.$('check_out').value;

    let value = moment(check_out).diff(check_in);
    value = Math.ceil(value / (86400 * 1000));

    return { value, unit: billing_hour };
  }

  // Rooms count
  @observable roomsBoundary = [1, 1];

  @action
  setRoomsBoundary({ min, max }) {
    this.roomsBoundary = [min, max];
  }

  @action
  unsetRoomsBoundary() {
    this.roomsBoundary = [1, 1];
  }

  @action
  calcRoomsCount() {
    const prices = _cloneDeep(this.dailyPrices);
    const tariff = this.tariff;

    const check_in = this.$('check_in').value;
    const check_out = this.$('check_out').value;

    const count = calcRoomsCount({ check_in, check_out, tariff, prices });
    return count;
  }

  @action
  coerceRoomsCount({ count }) {
    const currentValue = this.$('rooms_count').value || 1;

    let coercedValue = Math.min(count, currentValue);
    coercedValue = Math.max(1, coercedValue);

    return coercedValue;
  }

  // Payment
  @computed get payment() {
    if (!this.tariff) {
      return { total: { price: 0 }, bank_transfer: { price: 0 } };
    }

    const { tariff } = this;
    const slots = this.slots.map(slot => slot.value);

    return getPayment({ tariff, slots });
  }

  @computed get slots() {
    let slots = this.$('slots').fields.values();
    slots = Array.from(slots);
    slots = slots.filter(slot => !slot.isDeleted);

    return slots;
  }

  @action
  setSlots = ({ count }) => {
    this.selectedCount = count;
    this.coerceSlots();
  }

  @action
  unsetSlots() {
    const slots = [];
    this.update({ slots });
  }

  @action
  removeSlot(slot) {
    slot.destroy();

    this.selectedCount = this.selectedCount - 1;

    this.coerceSlots();
  }

  @action setRate({ slot, rate }) {
    const updatedAt = Date.now();
    slot.update({ rate, updatedAt });

    this.coerceSlots();
  }

  // Travellers
  @observable.ref travellers = [];

  @action setTravellers(travellers = []) {
    this.travellers = travellers.map(traveller => {
      const { first_name, last_name, middle_name } = traveller;
      const full_name = [last_name, first_name, middle_name].join(' ');

      const gender = GENDERS
        .find(item => item.value === traveller.gender);

      return { ...traveller, full_name, gender };
    });
  }

  @action unsetTravellers() {
    this.travellers = [];
  }

  @action setTraveller({ slot, traveller }) {
    slot.update({ traveller });

    this.coerceSlots();
  }

  // Availability
  @action setAvailability(options = {}) {
    const {
      order,
      contract,
      id,
      check_in,
      check_out,
      hotel,
      room_type,
      tariff,
      dailyPrices,
      occupation,
      slots,
      currentUser
    } = options;

    this.setCurrentUser(currentUser);
    this.setOrder(order);
    this.setHotel(hotel);
    this.setContract(contract);
    this.setRoomType(room_type);
    this.setTariff(tariff);
    this.setDailyPrices(dailyPrices);
    this.setDatesPeriod({ check_in, check_out });
    this.setOccupation(occupation);
    // this.setTravellers(travellers);

    if (slots) {
      this.selectedCount = slots.length;
      this.update({ id, slots });
    }

    this.coerceSlotsCount();
    this.coerceSlots();
  }

  @action unsetAvailability() {
    this.unsetOrder();
    this.unsetHotel();
    this.unsetRoomType();
    this.unsetTariff();
    this.unsetDailyPrices();
    this.unsetOccupation();
    this.unsetSlots();
  }

  @action setReservation({ hotel, room_type, tariff, ...rest }) {
    this.setHotel(hotel);
    this.setRoomType(room_type);
    this.setTariff(tariff);
    this.update({ ...rest });
  }

  @computed get hasAvailability() {
    return !!this.hotel && !!this.room_type && !!this.tariff;
  }

  @computed get availability() {
    const { hotel, room_type, tariff } = this;
    return { hotel, room_type, tariff };
  }

  // Beds count
  @computed get bedsCount() {
    return this.hasAvailability
      ? this.room_type.beds
      : 0;
  }

  @computed get extraBedsCount() {
    return this.hasAvailability
      ? this.room_type.extra_beds
      : 0;
  }

  // Dates
  @action setDatesPeriod({ check_in, check_out }) {
    // Correct and set dates
    const { checkIn, checkOut } = !!check_in && !!check_out
      ? this.coerceDatesByMinDays({ check_in, check_out })
      : { checkIn: check_in, checkOut: check_out };

    this.set({ check_in: checkIn, check_out: checkOut });

    // Run dates change handlers
    this.datesChangeHandlers({ check_in: checkIn, check_out: checkOut });
  }

  @action coerceDatesByMinDays({ check_in, check_out }) {
    const tariff = this.tariff;

    return coerceDatesByMinDays({ tariff, check_in, check_out });
  }

  datesChangeHandlers({ check_in, check_out }) {
    //
  }

  // Prices per day
  @observable.ref prices;

  @observable discount_percent;

  @computed get hasDiscount() {
    const value = Number(this.discount_percent);
    return value && value > 0;
  }

  setPrice({ price, discount_price }) {
    this.update({ price, discount_price });
  }

  unsetPrice() {
    const price = { amount: 0, currency: 'RUB' };
    this.update({ price });
  }

  // Export as params
  toParams() {
    const order = this.order;
    const values = this.values();

    return exportToQueryParams({ order, values });
  }
}

export default BookingForm;
